@tailwind base;
@tailwind components;
@tailwind utilities;
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Peoples::-webkit-scrollbar {
  display: none;
}